import React from "react";
import { useSelector } from "react-redux";


const Install = () => {

  const isInstalled = useSelector((state) => state?.donation?.zoomIsInstalled || false);
  const isAppInstalled = localStorage.getItem('iszoomappinstalled');
  
  const handleGiventTabOpen = () => {
    window.open('https://clickandpledge.com/givent', '_blank');
  };
 

  return (
    <>
   
     <div className="recurring_transaction_container">
        <div className="recurring_transaction_wrapper">
            <div className="recurring_transaction_card mt_2" id="recurring_main_tabs">
                <div className="recurring_transaction_card_body">
                  <div className="recurring_transaction_main_form" >
                      <div className="recurring_transaction_tab_content active">
                      <div className="transaction_info_logo_container">
                                <img src="newimgs/givent_logo_1.png" alt="Click and Pledge" style={{width:'150px'}}/>
                        </div>
                        <div className="transaction_info_wrapper">
                        <div className="form_input_wrapper mt_1">
                        <div className="recurring_transaction_divider" style={{textAlign:'left'}}>
                          <span>Turn your next Zoom Webinar into a Fundraiser</span>
                          {(isInstalled || isAppInstalled) ? ( <><br/><div className="form_input_wrapper mt_1" style={{textAlign:'center'}}><label for="" style={{fontSize:'14px'}}>Thank you for installing Givent</label></div></>) : null }
                          <p style={{lineHeight:'1.7rem',marginTop:'1rem'}}>
                        Get Started with <span style={{padding:'0',fontWeight:'900'}}>Givent:</span><br/>

 
                          1- Open the Zoom Desktop App and start your meeting.<br/>

                          2- In the toolbar, click on Apps and select Givent.<br/>

                          3- The host can then enter the Givent Token and share it with all participants.
                        </p>
                        </div>
                      
                        </div>
                       
                            
                            <div className="form_input_wrapper mt_2">
                           
                            <label for="">
                            
                            <div className="dw-col mt_1" style={{display:'flex', justifyContent:'center'}}>
                                <button className="full_width full_button" type="button" data-index="0"  onClick={handleGiventTabOpen}>
                                Click here to learn more about GIVENT
                                </button>
                            </div> </label>
                            </div>
                          
                        </div>
                        </div>
                      </div>
                      
                  </div>
                </div>

                </div> 
            </div> 
               
   

    </>
  );
};

export default Install;